export default {
  FormConjuntoEletricoBdgdConfig: () =>
    import(
      '@/components/setorizacoes/forms/FormConjuntoEletricoBdgdConfig.vue'
    ),
  FormMunicipioIbgeConfig: () =>
    import('@/components/setorizacoes/forms/FormMunicipioIbgeConfig.vue'),
  FormQuebrarAratBdgdConfig: () =>
    import('@/components/setorizacoes/forms/FormQuebrarAratBdgdConfig.vue'),
  FormDesenharPoligonosConfig: () =>
    import('@/components/setorizacoes/forms/FormDesenharPoligonosConfig.vue') // ultimo
};
