<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, selecione a forma como será feita a setorização
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <select-algoritmos-setorizacoes
          id="algoritmo-setorizacao"
          label="Regra de setorização"
          type="outlined"
          @algoritmoSelecionado="algoritmoSelecionado"
          :value="items.algoritmo ? items.algoritmo.id : null"
        />
      </v-col>
      <v-col
        cols="12"
        v-if="algoritmo"
      >
        <div class="text-center display-1 font-weight-normal mb-6">
          Exemplo de setorização da área de concessão usando a regra
        </div>
        <v-img
          class="white--text align-end"
          height="300px"
          :src="require(`@/assets/setorizacoes/${algoritmo.classe}.png`)"
          v-if="algoritmo.descricao"
        >
          <v-card-title style="color: #424242">
            {{ algoritmo.descricao }}
          </v-card-title>
        </v-img>
      </v-col>
      <v-col
        cols="12"
        v-if="algoritmo"
      >
        <div class="text-center display-1 font-weight-normal mb-6">
          Parâmetros de configuração da regra de setorização
        </div>
        <v-form class="mt-5">
          <component
            id="algoritmo-config"
            :month="month"
            :bdgdVersion="bdgdVersion"
            :is="formAlgoritmoConfig"
            @algoritmoConfigAtualizado="algoritmoConfigAtualizado"
            :algoritmoConfig="items.algoritmo_config"
          />
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import components from '@/components/setorizacoes/forms/components.js';

export default {
  name: 'SecondTabContent',
  components: {
    ...components,
    SelectAlgoritmosSetorizacoes: () =>
      import('@/components/general/SelectAlgoritmosSetorizacoes.vue')
  },
  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    items: {
      type: Object
    }
  },
  data() {
    return {
      algoritmo: {
        id: '',
        classe: null,
        descricao: null,
        nome: null
      },
      algoritmoConfig: {}
    };
  },
  mounted() {
    if (this.items) this.fillInputs();
  },
  computed: {
    formAlgoritmoConfig() {
      return this.algoritmo && this.algoritmo.classe
        ? `Form${this.algoritmo.classe}Config`
        : null;
    }
  },
  watch: {
    algoritmo() {
      this.emitEventDataUpdated();
    },
    algoritmoConfig() {
      this.emitEventDataUpdated();
    }
  },
  methods: {
    emitEventDataUpdated() {
      this.$emit('data:updated', {
        algoritmo: this.algoritmo,
        algoritmoConfig: this.algoritmoConfig
      });
    },
    algoritmoSelecionado(event) {
      this.algoritmo = event;
    },
    algoritmoConfigAtualizado(event) {
      this.algoritmoConfig = event;
    },
    fillInputs() {
      this.algoritmoSelecionado(this.items.algoritmo);
      this.algoritmoConfigAtualizado(this.items.algoritmo_config);
    }
  }
};
</script>
